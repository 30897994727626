import React, {createContext, useContext} from "react";

const DemoContext = createContext({
  capability: undefined,
  setCapability: undefined,
  customer: undefined,
  setCustomer: undefined,
  search: undefined,
  setSearch: undefined,
  resultCount: undefined,
  setResultCount: undefined,
});

export const DemoProvider = ({ children }) => {
  const [capability, setCapability] = React.useState('');
  const [customer, setCustomer] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [resultCount, setResultCount] = React.useState('');

  return (
    <DemoContext.Provider
      value={{ capability, setCapability, customer, setCustomer, search, setSearch, resultCount, setResultCount }}>
      {children}
    </DemoContext.Provider>
  )
}

export const useDemoContext = () => useContext(DemoContext);
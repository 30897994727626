import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import SwipeableView from './SwipeableView';

function ImageSection(props) {
    const { demo } = props;

    console.log(demo.image)

    return (
        <Paper
            square
            sx={{
                position: 'relative',
                backgroundColor: 'white',
                color: '#000',
                mb: 0,
            }}
        >
            {/* Increase the priority of the hero background image */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: '#e1e1e1',
                }}
            />
            <Container>
            <Grid container>
                <Grid item md={8}>
                    <Box
                        sx={{
                            position: 'relative',
                            p: { xs: 3, md: 6 },
                            pr: { md: 0 },
                        }}
                    >
                        <Typography variant="h4" paragraph>
                            {"Capability Preview"}
                        </Typography>
                        {/* <img alt="" sx={{ maxWidth: "100%" }} width="140%" src={`${process.env.PUBLIC_URL}${demo.overviewImage}`} /> */}
                        <img alt="" sx={{ maxWidth: "100%" }} width="140%" className="mySlides" src={`${process.env.PUBLIC_URL}${demo.image[0]}`}/>
                        <SwipeableView demo={demo} />
                    </Box>
                </Grid>
            </Grid>
            </Container>
        </Paper>
    );
}

export default ImageSection;
import * as React from 'react';

function SwipeableView(props) {
  const { demo } = props;
  console.log(demo);

  const image = `${process.env.PUBLIC_URL}/images/${demo.title}/${demo.image}`;
  console.log(image);

  return (
    <div>
      <img alt="" sx={{ maxWidth: "100%" }} width="140%" className="mySlides" src={`${process.env.PUBLIC_URL}/images/${demo.title}/${demo.image}`}/>
    </div>
  );
}

export default SwipeableView;
import { CssBaseline, } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';

import { DemoProvider } from './Components/DemoContext';
import DemoListPage from './Components/Demo List/DemoListPage';
import DemoPage from './Components/Demo Page/DemoPage';

// const item = {
//   "title": "Healthcare Demo",
//   "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et tincidunt est. Morbi aliquam sem sit amet dictum scelerisque. Integer sodales justo sem, nec euismod felis fringilla vitae. Morbi convallis magna interdum sem imperdiet dapibus. Morbi lobortis, lectus sit amet posuere cursus, ex lorem mollis ipsum, ut aliquam ligula leo in diam. In erat sapien, facilisis at egestas vel, aliquet quis ante. Proin ultricies tempor nulla at tincidunt. Donec congue pulvinar risus, molestie pharetra lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut nec rutrum arcu. Aliquam lorem dolor, euismod id molestie quis, rutrum sed ipsum. Suspendisse potenti. Ut ultricies ex turpis, dignissim gravida massa congue a. In hac habitasse platea dictumst.",
//   "slidedeckUrl": "example.com",
//   "videoUrl": "example.com",
// }

const defaultTheme = createTheme({
  typography: {
    h1: {
      fontWeight: 500,
      fontStyle: 'Gill Sans'
    },

    h4: {
      fontWeight: 200,
      color: "#006bb7"
    },

    h6: {
      fontWeight: 600,
      fontStyle: 'Gill Sans',
      color: "#64696F"
    },

    p: {
      fontWeight: 300,
      color: "#64696F"
    },

    fontFamily: [
      'proxima-nova',
      'sans-serif',
    ].join(',')
  },
  palette: {
    primary: {
      main: "#006BB5"
    },
    background: {
      default: "#fff"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
    <DemoProvider>
      <CssBaseline />
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<DemoListPage />} />
        <Route path="/:demoName" element={<DemoPage />} />
      </Routes>
    </BrowserRouter>
    </DemoProvider>
    </ThemeProvider>
  );
}

export default App;

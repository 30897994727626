import { Box, Typography } from '@mui/material'

function DemoListHeader() {
  return (
    <Box style={{"textAlign":"center"}} sx={{ flexGrow: 1 }}>
        <Typography variant="h2">
            Explore Koverse and Tenjin Solutions
        </Typography>
        <Typography variant="h5" paragraph>
          Find Koverse and Tenjin Solutions that fit your business needs
        </Typography>
    </Box>
  )
}

export default DemoListHeader;
import { Box, AppBar, Toolbar, Button, Grid } from '@mui/material';

function Header() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
          <Toolbar>
              <Grid container>
                <Grid item xs={2}>
                  <img style={{"marginRight":5}} width="100" alt="" src={"/images/SAIC-Logo-RGB-White-lg.png"}/>
                  <img style={{"marginBottom":10,}} width="100" alt="" src={"https://academy.koverse.com/pluginfile.php/1/theme_edumy/headerlogo1/1689870551/SAIC-KOVERSE-1C_RGB-1%20%281%29.svg"} />
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={1}><Button style={{"marginTop":8, "textTransform":"capitalize"}} variant="text" href="/" color="inherit">Home</Button></Grid>
                <Grid item xs={1}><Button style={{"marginTop":8, "textTransform":"capitalize"}} variant="text" href="/" color="inherit">ISSAIC</Button></Grid>
                <Grid item xs={1}><Button style={{"marginTop":8, "textTransform":"capitalize"}} variant="text" href="/" color="inherit">Offerings</Button></Grid>
                <Grid item xs={1}><Button style={{"marginTop":8, "textTransform":"capitalize"}} variant="text" href="/" color="inherit">Demos</Button></Grid>
                <Grid item xs={1}><Button style={{"marginTop":8, "textTransform":"capitalize"}} variant="text" href="/" color="inherit">Partners</Button></Grid>
                <Grid item xs={1}><Button style={{"marginTop":8, "textTransform":"capitalize"}} variant="text" href="/" color="inherit">FAQs</Button></Grid>
                <Grid item xs={1}><Button style={{"marginTop":8, "textTransform":"capitalize"}} variant="text" href="/" color="inherit">About Us</Button></Grid>
                <Grid item xs={1}/>
                <Grid item xs={1}><Button style={{"marginTop":8, "backgroundColor":"#015087"}} variant="text" href="/" color="inherit">Contact Us</Button></Grid>
              </Grid>
          </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header;
import {
  Link as RouterLink
} from 'react-router-dom';
import {Typography, Button, Card, CardMedia, CardContent, CardActions} from '@mui/material';

function DemoCard(props) {
  return (
    <Card sx={{ maxWidth: 345, minHeight: 350, maxHeight: 350,  overflow: "auto" }} elevation={8}>
      <CardMedia
        sx={{ height: 140 }}
        image={`${process.env.PUBLIC_URL}${props.demoJson.overviewImage}`}
        title={props.demoJson.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        {props.demoJson.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {props.demoJson.subtitle}
        </Typography>
      </CardContent>
      <CardActions>
        <RouterLink to={props.demoJson.title.replace(/\s/g, '')} state={{ demo: props.demoJson }} > <Button size="small">Learn More</Button></RouterLink>
      </CardActions>
    </Card>
  )
}

export default DemoCard;
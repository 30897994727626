import * as React from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, TextField, Button } from '@mui/material';
import { useDemoContext } from "../DemoContext";

function DemoFilter() {
  const { capability, setCapability, customer, setCustomer, setSearch, resultCount } = useDemoContext();

  const capabilitiesList = ["Data Fusion"," Data Discovery"," Entity Analysis"," Supply Chain"," Multimedia Management","Healthcare Analytics"," Mission Partner Environment"," Data Sharing"," FOIA"," Data Discovery","Document Management"," COP/SIP"," Streaming Data"," Simulation Routing"," DDIL"," Edge"," Data Sync"];
  const customerList = ["Navy", "Army", "Air Force", "NIC", "FEDCIV", "COCOMS", "USMC"];

  const handleCapabilityChange = (event) => {
    setCapability(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <>
    <Grid container style={{"boxShadow":"1px 1px 1px 1px rgba(0,28,36,0.3), -1px 1px 1px 1px rgba(0,28,36,0.15)"}}>
      <Grid container style={{"boxShadow":"0px 1px 0px 0 rgba(0,0,0,0.1)", "padding": "1.5rem 2rem"}}>
        <Grid item style={{"textAlign":"center"}} xs={4}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-helper-label">Relevant Capabilities</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper-capability"
              value={capability}
              label="Capability"
              onChange={handleCapabilityChange}
            >
              {Array.from(capabilitiesList).map((capability, index) => (
                <MenuItem value={capability} key={index}>{capability}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Search for demos with specific capabilities.</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item style={{"textAlign":"center"}} xs={3}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-helper-label">Relevant Customers</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper-customer"
              value={customer}
              label="Customer"
              onChange={handleCustomerChange}
            >
              {Array.from(customerList).map((customer, index) => (
                <MenuItem value={customer} key={index}>{customer}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Search for specific customer demos.</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item style={{"textAlign":"center"}} xs={3}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <TextField onChange={handleSearchChange} label="Search"></TextField>
            <FormHelperText style={{"textAlign":"center"}}>Search by text.</FormHelperText>
          </FormControl>  
        </Grid>
        <Grid item style={{"textAlign":"center", "marginTop":15}} xs={2}>
          <Button style={{"border":"1px solid"}} variant="text" href="/" color="inherit">Clear Selection</Button>
        </Grid>
        <Grid style={{"textAlign":"left"}} xs={12}>
          Total Results: {resultCount}
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}

export default DemoFilter;
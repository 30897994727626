import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

function DescriptionSection(props) {
    const { demo } = props;

    return (
        <Paper
            square
            sx={{
                position: 'relative',
                height: '600px',
                backgroundColor: 'white',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('/images/head.png')`,
                color: '#000',
                mb: 0,
            }}
        >
            {/* Increase the priority of the hero background image */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,0)',
                }}
            />
            <Grid container>
                <Grid style={{"textAlign":"left"}} item xs={7}>
                  <Box
                      sx={{
                          position: 'left',
                          p: { xs: 3, md: 6 },
                          pr: { md: 0 },
                      }}
                  >
                    <Typography style={{"paddingLeft":"40px","marginTop":"10px"}} variant="h4" paragraph>
                    {"Capability Summary"}
                    </Typography>
                    <Typography style={{"paddingLeft":"40px","whiteSpace": "pre-wrap"}} variant="p" color="inherit" paragraph>
                        {demo.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7}>
                      <Box
                          sx={{
                              position: 'relative',
                              paddingLeft: '50px',
                              pr: { md: 0 },
                          }}
                      >
                        <Typography style={{"paddingLeft":"40px","marginTop":"-30px"}} variant="h4" paragraph>
                        {"Relevant Customers"}
                        </Typography>
                        <Typography style={{"paddingLeft":"40px","whiteSpace": "pre-wrap", "marginTop": "-10px"}} variant="p" color="inherit" paragraph>
                            {demo.customerdata}
                        </Typography>
                      </Box>
                    </Grid>
            </Grid>
        </Paper>
    );
}

export default DescriptionSection;
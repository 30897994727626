import * as React from "react";
import '../../App.css';
import { Grid } from '@mui/material';
import DemoCard from './DemoCard';
import demosJson from '../../demo';
import { useDemoContext } from "../DemoContext";
import Fuse from 'fuse.js';

function DemoList() {
  const jsonList = React.useMemo(() => {return Array.from(demosJson)}, []);
  const [filteredList, setFilteredList] = React.useState(jsonList);
  const { capability, customer, search, setResultCount } = useDemoContext();

  const filterList = React.useCallback((capability, customer, search) => {
    let listToFilter = jsonList;
  
    if(capability != null && capability.length > 0){
      listToFilter = listToFilter.filter(record => record.useCase.includes(capability));
    }
  
    if(customer != null && customer.length > 0){
      listToFilter = listToFilter.filter(record => record.relevantcustomers.includes(customer));
    }
  
    if(search != null && search.length > 0){
      const fuseOptions = {
        isCaseSensitive: false,
        includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: [
          "title",
          "subtitle",
          "description", 
          "useCase",
          "overviewImage",
          "image",
          "imagetext",
          "linkText",
          "benefits.title",
          "benefits.description",
          "video",
          "talktrackPdf",
          "demoLink",
          "koverseLink",
          "koverseAccounts",
          "tenjinLink",
          "tenjinAccounts"
        ]
      };

      const fuse = new Fuse(listToFilter, fuseOptions);
      const fuseList = [];
      fuse.search(search).map((mapItem, index) => (
        fuseList.push(mapItem.item)
      ));
      listToFilter = fuseList;
    }
  
    setFilteredList(listToFilter);
  }, [jsonList]);

  React.useEffect(() => {
    setResultCount(filteredList.length);
  }, [setResultCount, filteredList]);

  React.useEffect(() => {
    filterList(capability, customer, search);
  }, [capability, customer, filterList, search])

  return (
      <main style={{"transform":"translate(1%)"}}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {filteredList.map((demo, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>          
                    <DemoCard demoJson={demo} />
                  </Grid>
                ))}
            </Grid>
      </main>
  );
}

export default DemoList;

// Schema Guide:
// Mandatory: title, subtitle, description, useCase, overviewImage, benefits, talktrackPdf


const demosJson = [
  {
    "title": "Capability Locator",
    "subtitle": "Comprehensive workflow to leverage large, disparate sources of unstructured information into capability recommendations",
    "description": "Koverse and Tenjin together form SAIC\u2019s Integrated Data Science Suite, which is designed to analyze massive amounts of data from disparate sources at scale. Its capability locator abilities enhance operational processes tenfold through appropriate data access and recommendation algorithms. Data is consolidated into a digestible form that non-technical users can understand, to provide actionable, AI-assisted insights that enable efficiency and mission-critical agility. Koverse co-locates various open-source intelligence (OSINT) data sources and other relevant data sources to quickly and easily meet mission needs and research and development efforts. Tenjin transforms these datasets and prepares them for machine learning (ML) in a transparent, no-code visual flow. The capability locator provides vendor recommendations from current government contracts and commercial technologies based on the requirements and the corresponding vendors\u2019 capabilities.",
    "useCase": "Data Fusion, Data Discovery ",
    "overviewImage": "/images/CapabilityLocator.jpg",
    "image": ["/images/Capability Locator/CL_1.png", "/images/Capability Locator/CL2.png", "/images/Capability Locator/CL3.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate datasets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Multi Media Handling ",
        "description": "Efficiently co-locate and index images, videos, and audio files for easy access, searchability, and retrieval for applications and users. Apply ABAC to the frame level of images and videos to make multimedia data instantly releasable and searchable to a diverse user base."
      },
      {
        "title": "Unlock Data Silos ",
        "description": "Koverse ingests a wide variety of data sources/stores, bypassing the need to create bespoke and expensive data ingest handlers. This allows for co-location of both structured and unstructured data, while logically separating access credentials at a fine-grained level for a diverse user base."
      },
      {
        "title": "Enterprise-Wide Search",
        "description": "Koverse conducts simultaneous searches across multiple domains or topics, allowing users to identify common patterns or connections between different datasets. It helps reveal valuable insights that would otherwise remain unnoticed when conducting isolated searches in individual areas."
      },
      {
        "title": "Limited Training Needs",
        "description": "Leverage a drag-and-drop environment with preloaded recipes to enable collaboration between nontechnical and technical users. Lower the barrier to entry to create powerful analytics in a no-code to high-code data science platform, where training time decreases from months to hours. "
      },
      {
        "title": "Mission Accelerators",
        "description": "Leverage high-performing TRL 6 and 7 machine learning models in an air-gapped, readily deployable environment to rapidly accelerate ML and AI development. Work with SMEs to apply transfer learning to tailor to your specific use case and ramp up to a TRL 8 or 9 for production-grade deployment."
      }
    ],
    "video": "",
    "talktrackPdf": "https://docs.google.com/presentation/d/1mDkf9W8TR4mc_fN3bO1ZotH7nYK3-d6a/edit?usp=drive_link&ouid=104262004862347650303&rtpof=true&sd=true",
    "customerdata": "This capability demonstration was initially created to persue a FEDSIM deal with NSIN and NIWC LANT, since then has been shown to a significant number of groups that have aquisition and a need for organizing requirements gathering. These groups include the following: NIWC Lant, DIU, ONR, FORSCOM G3, 635 SCOW, Navy OSD, PMA 234/298, NATO Seasparrow, DLA, VA and many more.\nTradeshows: SMD 2023.",
    "relevantcustomers": "Navy, Army, Air Force, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Electronic Warfare (EW) Fusion Capability",
    "subtitle": "Unstructured, semi-structured, and structured data fusion from disparate data sources and domains",
    "description": "EW fusion is an advanced capability for seamlessly integrating and analyzing diverse data from various locations. Automation, usability, traceability, and explainability are key focuses. It adeptly manages varied datasets, unifying them into a coherent view, while users access insights across databases for comprehensive decision making. Automation simplifies integration, cleansing, and validation, reducing manual work. The user-friendly interface ensures accessibility by catering to all expertise levels, simplifying data exploration. Transparency is a cornerstone, with tracked data sources and insights into the fusion process instilling confidence in the outcomes. Expert-guided implementation facilitates full capability utilization, empowering programs in dynamic missions to excel and adapt to evolving needs. This fusion of technology and implementation expertise equips organizations with essential tools and resources that ensure success in an ever-changing landscape.",
    "useCase": "Data Fusion, Entity Analysis",
    "overviewImage": "/images/ElectronicWarfareFusionCapability.jpg",
    "image": ["/images/Electronic Warfare Fusion Capability/EWFC_1.png", "/images/Electronic Warfare Fusion Capability/EWFC2.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Multi Media Handling ",
        "description": "Efficiently co-locate and index images, videos, and audio files for easy access, searchability, and retrieval for applications and users. Apply ABAC to the frame level of images and videos to make multimedia data instantly releasable and searchable to a diverse user base."
      },
      {
        "title": "Unlock Data Silos ",
        "description": "Koverse ingests a wide variety of data sources/stores, bypassing the need to create bespoke and expensive data ingest handlers. This allows for co-location of both structured and unstructured data, while logically separating access credentials at a fine-grained level for a diverse user base."
      },
      {
        "title": "Enterprise-Wide Search",
        "description": "Koverse conducts simultaneous searches across multiple domains or topics, allowing users to identify common patterns or connections between different datasets. It helps reveal valuable insights that would otherwise remain unnoticed when conducting isolated searches in individual areas."
      },
      {
        "title": "Data Fusion",
        "description": "Fuse multiple sources of intelligence at massive scale with live streaming data collection and prepare data for distribution on a need-to-know basis in near real time. Execute kill chains  faster with higher fidelity by expanding data sources and increasing the speed from ingestion to inference with AI-assisted decision making. "
      },
      {
        "title": "Mission Partner Environment",
        "description": "Koverse enables the strategic obfuscation and dissemination of data in real time to support secure mission partner environments. This creates an ideal data-centric environment that allows for the sharing of mixed classifications of data, both internally and with critical allies."
      },
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      }
    ],
    "video": "",
    "talktrackPdf": "",
    "customerdata": "This capability demonstration was initially created to meet a need from NAVAIR's EWDS Lab at NAS Point Mugu. The capability cut a manually data fusion process that previously took 28 days down to 30 seconds. This demonstation is now a contracted project that SAIC is currently working on that other labs within NAVAIR are interested in. This capability has been refrenced in most if not all of our mid-large government meetings as a proof point and past performace metric of our teams successes.\nTradeshows: AFA, GVSETS.",
    "relevantcustomers": "Navy, Air Force, NIC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Data Centric Supply Chain Risk Mitigation",
    "subtitle": "Persistent supply chain monitoring: entity extraction, topic modeling, and semantic scoring in multiple languages",
    "description": "SAIC\u2019s supply chain monitoring capability is distinguished by advanced features that minimize risk, optimize operations, and ensure transparency. Entity Identification and Extraction are sophisticated algorithms employed to precisely locate and extract essential supply chain components, facilitating comprehensive tracking and analysis throughout the entire supply chain. Topic Modeling and Semantic Scoring extracts valuable insights from unstructured data sources and autonomously identifies and prioritizes critical topics, enabling prompt, well-informed decision making. In recognition of the global nature of supply chains, Tenjin offers robust multilingual capabilities. It seamlessly processes data in diverse languages, ensuring extensive monitoring and analysis across varied markets and regions. To further enhance accuracy, Adjudication and Feedback features allow subject matter experts to review and validate automated results, refining system performance and upholding the highest level of data integrity.",
    "useCase": "Supply Chain, Data Discovery, Entity Analysis",
    "overviewImage": "/images/DataCentricRiskMitigation.jpg",
    "image": ["/images/Data Centric Risk Mitigation for Supply Chain Management/DCRMSCM_1.png", "/images/Data Centric Risk Mitigation for Supply Chain Management/DCRMSCM2.png", "/images/Data Centric Risk Mitigation for Supply Chain Management/DCRMSCM3.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate datasets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Limited Training Needs",
        "description": "Leverage a drag-and-drop environment with preloaded recipes to enable collaboration between nontechnical and technical users. Lower the barrier to entry to create powerful analytics in a no-code to high-code data science platform, where training time decreases from months to hours. "
      },
      {
        "title": "Unlock Data Silos ",
        "description": "Koverse ingests a wide variety of data sources/stores, bypassing the need to create bespoke and expensive data ingest handlers. This allows for co-location of both structured and unstructured data, while logically separating access credentials at a fine-grained level for a diverse user base."
      },
      {
        "title": "Enterprise-Wide Search",
        "description": "Koverse conducts simultaneous searches across multiple domains or topics, allowing users to identify common patterns or connections between different datasets. It helps reveal valuable insights that would otherwise remain unnoticed when conducting isolated searches in individual areas."
      },
      {
        "title": "No Vendor Lock",
        "description": "Koverse stores all data in its native format and the data remains fully exportable at any time. Through the API, Koverse makes data available to any existing and future third-party applications, including visualization tools, BI reporting, and other mission-critical systems."
      },
      {
        "title": "Mission Accelerators",
        "description": "Leverage high-performing TRL 6 and 7 machine learning models in an air-gapped, readily deployable environment to rapidly accelerate ML and AI development. Work with SMEs to apply transfer learning to tailor to your specific use case and ramp up to a TRL 8 or 9 for production-grade deployment."
      }
    ],
    "video": "",
    "talktrackPdf": "https://docs.google.com/presentation/d/1Lg4g3pl9nsALAdJGuaASs91LC0K5wtpI/edit?usp=drive_link&ouid=104262004862347650303&rtpof=true&sd=true",
    "customerdata": "This capability demonstration was initially created to meet a need from NIWC LANT and has been more recenly shown to the following groups: PMA 234/298, NATO SeaSparrow, DLA and the VA. It has also been shown to many other groups with use cases centered around supply chain management and asset management. \nTradeshows: AFCEA West, REAIM, AUSA, Sea Air and Space, MDEX, Technet Cyber, SOFIC, Modern Day Marine, Technet Augusta",
    "relevantcustomers": "Navy, Army, Air Force, NIC, FEDCIV, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Entity Based Production and Monitoring",
    "subtitle": "Streamlines case disposition and data masking for enhanced security",
    "description": "Entity-based production and monitoring intelligently manages and processes sensitive data, ensuring that only authorized users have access to full information while protecting privacy. With a focus on efficiency and compliance, this capability facilitates seamless production and monitoring of critical data while safeguarding confidentiality and maintaining regulatory standards. Additionally, entity-based production and monitoring offers a wide range of functionality through multiple third-party integration apps. Embedded apps include object detection, file uploading, optical character recognition (OCR), and map functionality. These integrations further enhance data processing, enabling advanced data analysis and visualization while maintaining a strong emphasis on data security and user access control.",
    "useCase": "Data Fusion, Entity Analysis",
    "overviewImage": "/images/EntityBasedProductionandMonitoring.jpg",
    "image": ["/images/Entity Based Production and Monitoring/EBPM_1.png", "/images/Entity Based Production and Monitoring/EBPM2.png", "/images/Entity Based Production and Monitoring/EBPM3.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate datasets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Limited Training Needs",
        "description": "Leverage a drag-and-drop environment with preloaded recipes to enable collaboration between nontechnical and technical users. Lower the barrier to entry to create powerful analytics in a no-code to high-code data science platform, where training time decreases from months to hours. "
      },
      {
        "title": "Unlock Data Silos ",
        "description": "Koverse ingests a wide variety of data sources/stores, bypassing the need to create bespoke and expensive data ingest handlers. This allows for co-location of both structured and unstructured data, while logically separating access credentials at a fine-grained level for a diverse user base."
      },
      {
        "title": "Interoperability",
        "description": "Koverse serves as the single source of truth for data access by enforcing MLS on downstream applications. Koverse integrates with existing ICAM solutions and serves subsets of data in its native format to modernize applications' security posture that are not data tagging aware or capable."
      },
      {
        "title": "Mission Accelerators",
        "description": "Leverage high-performing TRL 6 and 7 machine learning models in an air-gapped, readily deployable environment to rapidly accelerate ML and AI development. Work with SMEs to apply transfer learning to tailor to your specific use case and ramp up to a TRL 8 or 9 for production-grade deployment."
      },
      {
        "title": "Scalable Architecture",
        "description": "Koverse scales linearly with hardware availability for ingestion and storage requirements. Proven at multi-million record per second ingest rates, Koverse is built on Accumulo's distributed big data architecture, which scales horizontally to deliver sub-second queries at petabyte-level and beyond scales."
      }
    ],
    "video": "",
    "talktrackPdf": "",
    "customerdata": "This capability demonstration was initially created to meet a need from DOJ and has been declassified to present a wide variety of capabilities within Tenjin using Koverse as the backend. For the better part of 2022 this demo was shown at almost every customer meeting to show the full capabilities of Tenjin and was also shown at a Technical Exchange Meeting at DISA per request from DISA. This Demonstation is now a contracted project that SAIC is currently working.\nTradeshows: AFCEA West, AUSA, Sea Air and Space, Technet Cyber, Modern Day Marine, Technet Augusta",
    "relevantcustomers": "Navy, Army, Air Force, NIC, FEDCIV, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Multi-Media Data Management and Labeling",
    "subtitle": "Ingest all data types regardless of schema or origin, including videos, at scale and speed",
    "description": "Koverse\u2019s multimedia data storage capability stands out due to its unique security and labeling features, which provide organizations and programs with a distinct competitive advantage. This feature enables future use cases to now be possible by allowing organizations to make use of all their video and image repositories. By efficiently tagging certain frames of videos using detection and computer vision algorithms, video files can now surface insights and bring users directly to the frames of interest. The solution is designed for flexibility and scalability, seamlessly integrating into existing workflows and adapting to evolving business needs. With our team of data management experts guiding the implementation process, it ensures maximum utilization of this capability and empowers programs to thrive against ever-evolving mission needs.",
    "useCase": "Data Fusion, Multimedia Management",
    "overviewImage": "/images/MultiMediaDataManagementLabeling.jpg",
    "image": ["/images/Multi-Media Data Management and Labeling/MMDML_1.png", "/images/Multi-Media Data Management and Labeling/MMDML2.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Multi Media Handling ",
        "description": "Efficiently co-locate and index images, videos, and audio files for easy access, searchability, and retrieval for applications and users. Apply ABAC to the frame level of images and videos to make multimedia data instantly releasable and searchable to a diverse user base."
      },
      {
        "title": "Data Format Agnostic",
        "description": "Koverse's schemaless ingest and open architecture allow it to ingest data of any format, and from any source, to interoperate with any existing pipeline. Data, including metadata, is ingested through 50+ prebuilt connectors in its native format, saving costs and time on fragile ETL pipelines."
      },
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate datasets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Multi Media Handling ",
        "description": "Efficiently co-locate and index images, videos, and audio files for easy access, searchability, and retrieval for applications and users. Apply ABAC to the frame level of images and videos to make multimedia data instantly releasable and searchable to a diverse user base."
      },
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      },
      {
        "title": "Scalable Architecture",
        "description": "Koverse scales linearly with hardware availability for ingestion and storage requirements. Proven at multi-million record per second ingest rates, Koverse is built on Accumulo's distributed big data architecture, which scales horizontally to deliver sub-second queries at petabyte-level and beyond scales."
      }
    ],
    "video": "",
    "talktrackPdf": "Pending",
    "customerdata": "This capability demonstration was initially created to meet a need from the 1st Capabilities Integration Group (1CIG) and uses their video data within our demo. The feature is relatively new but is being shown to more and more groups within the DOD and specifically to multiple Intelligence Community Customers. ",
    "relevantcustomers": "Navy, Army, Air Force, NIC, FEDCIV, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Healthcare Data Fusion",
    "subtitle": "Storage, analysis, and fusion of disparate healthcare datasets",
    "description": "Koverse efficiently manages diverse health datasets from various sources, unifying them into a comprehensive patient record view. By combining proprietary and open-source data, healthcare professionals gain insightful information for informed decision making. Our system ensures unified access to patient entities across databases, enhancing care coordination and outcomes. Automation streamlines data integration, cleansing, and validation, reducing the administrative workload and ensuring accuracy. With a user-friendly interface, even those with limited expertise can navigate complex health data effortlessly. Stringent security measures protect sensitive data while adhering to industry standards and data protection regulations. Prioritizing traceability allows professionals to track the data\u2019s origin and changes and offer explainability and revealing insights into the fusion process. The solution as a whole optimizes health data management, promoting efficient, secure, and transparent healthcare operations.",
    "useCase": "Data Fusion, Heathcare Analytics",
    "overviewImage": "/images/HealthcareDataFusion.jpg",
    "image": "",
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Data Format Agnostic",
        "description": "Koverse's schemaless ingest and open architecture allow it to ingest data of any format, and from any source, to interoperate with any existing pipeline. Data, including metadata, is ingested through 50+ prebuilt connectors in its native format, saving costs and time on fragile ETL pipelines."
      },
      {
        "title": "Enterprise-Wide Search",
        "description": "Koverse conducts simultaneous searches across multiple domains or topics, allowing users to identify common patterns or connections between different datasets. It helps reveal valuable insights that would otherwise remain unnoticed when conducting isolated searches in individual areas."
      },
      {
        "title": "No Vendor Lock",
        "description": "Koverse stores all data in its native format and the data remains fully exportable at any time. Through the API, Koverse makes data available to any existing and future third-party applications, including visualization tools, BI reporting, and other mission-critical systems."
      },
      {
        "title": "Scalable Architecture",
        "description": "Koverse scales linearly with hardware availability for ingestion and storage requirements. Proven at multi-million record per second ingest rates, Koverse is built on Accumulo's distributed big data architecture, which scales horizontally to deliver sub-second queries at petabyte-level and beyond scales."
      },
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      },
      {
        "title": "Limited Training Needs",
        "description": "Leverage a drag-and-drop environment with preloaded recipes to enable collaboration between nontechnical and technical users. Lower the barrier to entry to create powerful analytics in a no-code to high-code data science platform, where training time decreases from months to hours. "
      }
    ],
    "video": "",
    "talktrackPdf": "",
    "customerdata": "This capability demonstration is in backlog and development to meet a need from TBD and has been shown to the following groups within the TBD and TBD including abc, efg, hij, klm.",
    "relevantcustomers": "FEDCIV",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Data Management and Search Analytic ",
    "subtitle": "Enterprise-wide search on information logically separated with attribute-based access control (ABAC)",
    "description": "Koverse's cross-cutting search on structured, unstructured, and hierarchical data provides a key benefit for analysts to find the data they need fast. This key search feature, coupled with Koverse's unique ability to co-locate data of any sensitivity and source within a zero-trust multi-level security environment, supports any use case and mission regardless of data sensitivity. Analysts of diverse backgrounds can search across single or multiple datasets and only see the information they are allowed to see. Avoiding the need to manually scrub and sanitize data for releasability and preventing version control issues increases the speed of information within and across organizations. Users can quickly find and share the data they need in real time to empower data-driven decisions and enhance adaptability at the speed of the mission.",
    "useCase": "Data Discovery, Mission Partner Environment, Data Sharing",
    "overviewImage": "/images/MissileInformationCrossSearch.jpg",
    "image": ["/images/Data Management and Search Analytic/DMSA_1.png", "/images/Data Management and Search Analytic/DMSA2.png", "/images/Data Management and Search Analytic/DMSA3.png", "/images/Data Management and Search Analytic/DMSA4.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Enterprise-Wide Search",
        "description": "Koverse conducts simultaneous searches across multiple domains or topics, allowing users to identify common patterns or connections between different datasets. It helps reveal valuable insights that would otherwise remain unnoticed when conducting isolated searches in individual areas."
      },
      {
        "title": "Mission Partner Environment",
        "description": "Koverse enables the strategic obfuscation and dissemination of data in real time to support secure mission partner environments. This creates an ideal data-centric environment that allows for the sharing of mixed classifications of data, both internally and with critical allies."
      },
      {
        "title": "Unlock Data Silos ",
        "description": "Koverse ingests a wide variety of data sources/stores, bypassing the need to create bespoke and expensive data ingest handlers. This allows for co-location of both structured and unstructured data, while logically separating access credentials at a fine-grained level for a diverse user base."
      },
      {
        "title": "Scalable Architecture",
        "description": "Koverse scales linearly with hardware availability for ingestion and storage requirements. Proven at multi-million record per second ingest rates, Koverse is built on Accumulo's distributed big data architecture, which scales horizontally to deliver sub-second queries at petabyte-level and beyond scales."
      },
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate datasets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      }
    ],
    "video": "",
    "talktrackPdf": "talk track: https://drive.google.com/drive/folders/1mUGn7lO2NVLqp9Z5rMnTABPwr5UrzLVr       click track: https://drive.google.com/drive/folders/1mUGn7lO2NVLqp9Z5rMnTABPwr5UrzLVr",
    "customerdata": "This capability demonstration was initially created to meet a need from MDA. This is a staple search capability and is generic to show to almost any client that wants to see Koverse.\nTradeshows: SMD 2023",
    "relevantcustomers": "Navy, Army, Air Force, NIC, FEDCIV, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Document Management and Redaction",
    "subtitle": "Specialized tools for redacting and viewing documents with attribute-based access control (ABAC)",
    "description": "Koverse enables fine-grained document portion marking and redaction using attribute-based access control (ABAC) to support document management modernization efforts. In existing document management systems, multiple copies of documents are retained for every potential classification level, creating a version management and storage problem. Koverse solves these issues by storing one copy of a document with portions of the document controlled at different access levels through fine-grained ABAC management. In addition, all documents are indexed for agency-wide search capabilities to enable rapid document discovery and FOIA compliance.",
    "useCase": "Document Management, FOIA, Data Discovery, Mission Partner Environment, Data Sharing",
    "overviewImage": "/images/Redactor.png",
    "image": "",
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Multi Media Handling ",
        "description": "Efficiently co-locate and index images, videos, and audio files for easy access, searchability, and retrieval for applications and users. Apply ABAC to the frame level of images and videos to make multimedia data instantly releasable and searchable to a diverse user base."
      },
      {
        "title": "Document Redaction",
        "description": "Seamlessly manage access controls on your unstructured data including portion marked documents, diagrams, images, and more. Leverage OCR and NLP models to automate your manual processess and provide human-in-the-loop integration with Koverse's drag and drop Redaction Capability for your complex data types and most sensitive use cases."
      },
      {
        "title": "Unlock Data Silos ",
        "description": "Koverse ingests a wide variety of data sources/stores, bypassing the need to create bespoke and expensive data ingest handlers. This allows for co-location of both structured and unstructured data, while logically separating access credentials at a fine-grained level for a diverse user base."
      },
      {
        "title": "Explainable Data Management and Traceable AI",
        "description": "Koverse retains an immutable raw ingest version of all data along with a comprehensive set of audit logs for all user actions taken within the system to give system administrators the ability to monitor, track, and visualize these logs for compliance."
      },
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      },
      {
        "title": "Scalable Architecture",
        "description": "Koverse scales linearly with hardware availability for ingestion and storage requirements. Proven at multi-million record per second ingest rates, Koverse is built on Accumulo's distributed big data architecture, which scales horizontally to deliver sub-second queries at petabyte-level and beyond scales."
      }
    ],
    "video": "",
    "talktrackPdf": "",
    "customerdata": "This capability demonstration was initially created to meet a need from DE Groups and has been shown to multiple groups within the Navy. The capability is an upgrade to our previous portion marking capability that has been shown to almost every goverment customer that has some form of document management need or data sharing need.",
    "relevantcustomers": "Navy, Army, Air Force, NIC, FEDCIV, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "MLS Airspace COP",
    "subtitle": "Live flight mapping with obfuscated high-security information",
    "description": "The MLS Airspace common operating picture (COP) capability exemplifies seamless data fusion across Koverse instances. The comprehensive COP perspective dynamically presents critical location data and real-time details about friendly and hostile aircraft, enhancing decision making by illuminating the operational context. Dual Koverse instance data hosting ensures availability and fault tolerance to mitigate data loss risks. During instance failures, the solution seamlessly and resiliently executes failover, transitioning data processing to the mirrored partner instance, ensuring uninterrupted data access and minimal downtimes for operational continuity. Real-time live aircraft data is vividly visualized on the screen, effectively conveying smooth data ingestion and replication across both instances.",
    "useCase": "Data Fusion, COP/SIP, Streaming Data, Mission Partner Environment, Data Sharing",
    "overviewImage": "/images/MLSAirspaceCOP.jpg",
    "image": ["/images/MLS Airspace COP/MLSACOP_1.png", "/images/MLS Airspace COP/MLSACOP2.png", "/images/MLS Airspace COP/MLSACOP3.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      },
      {
        "title": "Data Fusion",
        "description": "Fuse multiple sources of intelligence at massive scale with live streaming data collection and prepare data for distribution on a need-to-know basis in near real time. Execute kill chains  faster with higher fidelity by expanding data sources and increasing the speed from ingestion to inference with AI-assisted decision making. "
      },
      {
        "title": "Data Format Agnostic",
        "description": "Koverse's schemaless ingest and open architecture allow it to ingest data of any format, and from any source, to interoperate with any existing pipeline. Data, including metadata, is ingested through 50+ prebuilt connectors in its native format, saving costs and time on fragile ETL pipelines."
      },
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate datasets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Data Fusion",
        "description": "Fuse multiple sources of intelligence at massive scale with live streaming data collection and prepare data for distribution on a need-to-know basis in near real time. Execute kill chains  faster with higher fidelity by expanding data sources and increasing the speed from ingestion to inference with AI-assisted decision making. "
      },
      {
        "title": "Scalable Architecture",
        "description": "Koverse scales linearly with hardware availability for ingestion and storage requirements. Proven at multi-million record per second ingest rates, Koverse is built on Accumulo's distributed big data architecture, which scales horizontally to deliver sub-second queries at petabyte-level and beyond scales."
      }
    ],
    "video": "",
    "talktrackPdf": "",
    "customerdata": "This capability demonstration was initially created to meet a need from STRATCOM NDRC and NEC and has been shown to multiple groups within the DoD, primarily AFDODAC customers. \nTradeshows: AFA 2023, SMD 2023, ASC 2023.",
    "relevantcustomers": "Navy, Army, Air Force, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "MLS Ground Forces COP",
    "subtitle": "Ground troop mapping simulation with obfuscated high-security information",
    "description": "Koverse provides a common operating picture (COP) for both U.S. troops and coalition partners with multi-level security. It can modernize existing applications by enforcing Koverse\u2019s attribute-based access controls (ABAC) without the application being data tagging aware or capable. Multi-level security enables the strategic obfuscation and dissemination of data in real time to coalition partners. The coalition view can see select information such as the location of certain U.S. troops, enemies, and their own troops. The U.S. view can see all data within the COP. Koverse can handle access credentials of any complexity, whether internal or external to the data, such as need-to-know, handling caveat, data classification, and more. With this unique capability, organizations can share information with a diverse user base, including coalition partners, in real time, to execute mission objectives while maintaining necessary secrecy.",
    "useCase": "Data Fusion, COP/SIP, Mission Partner Environment, Data Sharing",
    "overviewImage": "/images/MLSGroundforcesCOP.jpg",
    "image": ["/images/MLS Ground Forces COP/MLSGFCOP_1.png", "/images/MLS Ground Forces COP/MLSGFCOP2.png", "/images/MLS Ground Forces COP/MLSGFCOP3.png", "/images/MLS Ground Forces COP/MLSGFCOP4.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      },
      {
        "title": "Data Fusion",
        "description": "Fuse multiple sources of intelligence at massive scale with live streaming data collection and prepare data for distribution on a need-to-know basis in near real time. Execute kill chains  faster with higher fidelity by expanding data sources and increasing the speed from ingestion to inference with AI-assisted decision making. "
      },
      {
        "title": "Data Format Agnostic",
        "description": "Koverse's schemaless ingest and open architecture allow it to ingest data of any format, and from any source, to interoperate with any existing pipeline. Data, including metadata, is ingested through 50+ prebuilt connectors in its native format, saving costs and time on fragile ETL pipelines."
      },
      {
        "title": "Enables Secure, Rapid AI/ML Development",
        "description": "Koverse acts as a secure, single source of truth for machine learning development. Co-locating disparate datasets in one data store enables the most accurate models at the fastest development speeds."
      },
      {
        "title": "Data Fusion",
        "description": "Fuse multiple sources of intelligence at massive scale with live streaming data collection and prepare data for distribution on a need-to-know basis in near real time. Execute kill chains  faster with higher fidelity by expanding data sources and increasing the speed from ingestion to inference with AI-assisted decision making. "
      },
      {
        "title": "Scalable Architecture",
        "description": "Koverse scales linearly with hardware availability for ingestion and storage requirements. Proven at multi-million record per second ingest rates, Koverse is built on Accumulo's distributed big data architecture, which scales horizontally to deliver sub-second queries at petabyte-level and beyond scales."
      }
    ],
    "video": "",
    "talktrackPdf": "https://docs.google.com/presentation/d/1-6gFeyOx1rDxgoT8Kmb51LDRPFbLB-KaxUYKQU6I_Xs/edit#slide=id.g257c26ea94d_0_499",
    "customerdata": "This capability demonstration was initially created for the AUSA Trade show and has been shown successful in showing an MLS common operating picture.  It has been shown to the following groups within the Army including the Redstone Arsenal Garrison.\nTradeshows: AUSA Global Force 2023, Technet Augusta 2023.",
    "relevantcustomers": "Navy, Army, Air Force, FEDCIV, COCOMS, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Koverse Rules Engine LVC Application",
    "subtitle": "Real-time attribute-based access control (ABAC) for live, virtual, and constructed (LVC) environments",
    "description": "Powered by the Koverse Rules Engine, the Joint Simulation Environment (JSE) enables fighter pilots from U.S. and NATO forces to train the way they fight. Koverse enhances the NGTS simulation software by applying ABAC to strategically obfuscate and disseminate location data for aircraft and weapons, as well as the capabilities of those weapons between U.S. and NATO forces inside the same environment. The Koverse Routing Service applies these enhancements to the current environment with less than one millisecond of latency while also securitizing playback for previous simulations as well.",
    "useCase": "Data Fusion, Simulation Routing, Streaming Data, Mission Partner Environment, Data Sharing",
    "overviewImage": "/images/KoverseRulesEngineLVCApplication.jpg",
    "image": ["/images/Koverse Rules Engine LVC Application/KRELVCA_1.png", "/images/Koverse Rules Engine LVC Application/KRELVCA2.png", "/images/Koverse Rules Engine LVC Application/KRELVCA3.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "Competitive Advantage",
        "description": "Koverse has a strong advantage over the primary competitor in the space, RadiantMercury, due to the many-to-many redaction capabilities, the stateful simulation analysis that enables complex rulesets, and the ease of use in setting up new rules for new scenarios."
      },
      {
        "title": "Mission Partner Environment",
        "description": "Koverse enables the strategic obfuscation and dissemination of data in real time to support secure mission partner environments. This creates an ideal data-centric environment that allows for the sharing of mixed classifications of data, both internally and with critical allies."
      },
      {
        "title": "Explainable Data Management and Traceable AI",
        "description": "Koverse retains an immutable raw ingest version of all data along with a comprehensive set of audit logs for all user actions taken within the system to give system administrators the ability to monitor, track, and visualize these logs for compliance."
      },
      {
        "title": "Interoperability",
        "description": "Koverse serves as the single source of truth for data access by enforcing MLS on downstream applications. Koverse integrates with existing ICAM solutions and serves subsets of data in its native format to modernize applications' security posture that are not data tagging aware or capable."
      },
      {
        "title": "Data Fusion",
        "description": "Fuse multiple sources of intelligence at massive scale with live streaming data collection and prepare data for distribution on a need-to-know basis in near real time. Execute kill chains  faster with higher fidelity by expanding data sources and increasing the speed from ingestion to inference with AI-assisted decision making. "
      },
      {
        "title": "Data Format Agnostic",
        "description": "Koverse's schemaless ingest and open architecture allow it to ingest data of any format, and from any source, to interoperate with any existing pipeline. Data, including metadata, is ingested through 50+ prebuilt connectors in its native format, saving costs and time on fragile ETL pipelines."
      }
    ],
    "video": "",
    "talktrackPdf": "",
    "customerdata": "This capability demonstration was initially created to meet a need from the Joint Simulation Environment of the United States Airforce and has been shown to the following groups within the USAF and Navy including NAVAIR PMA 298, DMOC, LMOC, TF59 and many other. This capability is a staple for our team to show real life use cases using ABAC in a clear and consise way.\nTradeshows: AFA,  SMD, DODIS, AFCEA West, REAIM, AUSA, Sea Air and Space, MDEX, Technet Cyber, SOFIC, Modern Day Marine, Technet Augusta",
    "relevantcustomers": "Navy, Army, Air Force, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  },
  {
    "title": "Koverse and Tenjin DDIL",
    "subtitle": "Koverse and Tenjin applications in denied, disrupted, intermittent, and limited (DDIL) edge environments",
    "description": "Koverse and Tenjin's containerized architecture enables deployments on small form factor devices, as small as a Raspberry Pi. Koverse and Tenjin excel in DDIL environments where edge devices can collect and process data locally without connectivity, then sync when connectivity is established, while prioritizing what information is synced based on bandwidth constraints.",
    "useCase": "DDIL, Edge",
    "overviewImage": "/images/KoverseandTenjinDDIL.jpg",
    "image": ["/images/Koverse and Tenjin DDIL/KTDDIL_1.png", "/images/Koverse and Tenjin DDIL/KTDDIL2.png", "/images/Koverse and Tenjin DDIL/KTDDIL3.png"],
    "imagetext": "",
    "linkText": "Continue Reading...",
    "benefits": [
      {
        "title": "DDIL Edge Processing",
        "description": "Collect, secure, and process data locally in DDIL environments. Apply ABAC tags the moment the data is produced to prepare for distribution. Process data offline to stay undetected in hot zones while optimizing signal management."
      },
      {
        "title": "Message Prioritization",
        "description": "Prioritize what data is synchronized when connectivity is established based on available bandwidth. Koverse can prioritize contextualized model output and AI artifacts in low-bandwidth environments and switch to uploading unprocessed raw data when connectivity is strong."
      },
      {
        "title": "Data Mesh Architecture",
        "description": "Koverse nodes sync when connectivity is established to ensure data is aggregated and deduplicated across edge devices and larger cloud Koverse instances."
      },
      {
        "title": "Interoperability",
        "description": "Koverse serves as the single source of truth for data access by enforcing MLS on downstream applications. Koverse integrates with existing ICAM solutions and serves subsets of data in its native format to modernize applications' security posture that are not data tagging aware or capable."
      },
      {
        "title": "Limited Training Needs",
        "description": "Leverage a drag-and-drop environment with preloaded recipes to enable collaboration between nontechnical and technical users. Lower the barrier to entry to create powerful analytics in a no-code to high-code data science platform, where training time decreases from months to hours. "
      },
      {
        "title": "Industry-Leading ABAC",
        "description": "Koverse applies attribute-based access control (ABAC) as an intrinsic part of data storage at a fine-grained level. Multiple users can search across the same dataset and see different views based on data classification, handling caveat, need-to-know, location, role, and more."
      }
    ],
    "video": "",
    "talktrackPdf": "",
    "customerdata": "This capability demonstration was initially created to meet a need from USMC MCI as a result of a Modern Day Marine ask. There has been significatn traction within the USMC on this topic. It has been shown to multiple groups within the USMC and also DARPA ACO. Any customer with an edge use case will hear about our new Koverse Edge offering and this specific capability.\nTradeshows: GVSETS 2023, CDCA 2022.",
    "relevantcustomers": "Navy, Army, Air Force, NIC, USMC",
    "demoLink": "",
    "koverseLink": "",
    "koverseAccounts": [],
    "tenjinLink": "",
    "tenjinAccounts": []
  }
]

export default demosJson;
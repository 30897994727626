import * as React from 'react';
import { Paper, Typography, Grid, Box, Container, Card, CardContent, Chip } from '@mui/material';

function BenefitsSection(props) {
    const { demo } = props;

    const handleClick = () => {

    }

    return (
        <Paper
            square
            sx={{
                position: 'relative',
                backgroundColor: 'white',
                color: '#000',
                mb: 0,
            }}
        >
            {/* Increase the priority of the hero background image */}
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: 'rgba(0,0,0,0)',
                    flexGrow: 1,
                }}
            />
            <Container>
            <Grid container>
                <Grid item md={12}>
                    <Box
                        sx={{
                            p: { xs: 3, md: 6 },
                            pr: { md: 0 },
                            justifyContent: 'center'
                        }}
                    >
                      <Typography variant="h4" paragraph>
                          {"Key Highlights"}
                      </Typography>
                      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                          {demo.benefits.map((benefit, index) => (
                              <Grid item xs={2} sm={4} md={4} key={index}>
                                  <div>
                                      <Card sx={{ maxWidth: 345, minHeight: 350, maxHeight: 350,  overflow: "auto" }} elevation={8}>
                                      <CardContent>
                                        <Typography variant="h6" paragraph>
                                            {benefit.title}
                                        </Typography>
                                        <Typography variant="p" color="inherit" paragraph>
                                            {benefit.description}
                                        </Typography>
                                      </CardContent>
                                      </Card>
                                  </div>
                              </Grid>
                          ))}
                      </Grid>
                      <Chip
                        label="View Demo"
                        style={{"backgroundColor": "#015087", "color":"white","marginLeft":"30.5%", "marginTop":"3%", "width":"20%","fontSize":"18px" }}
                        onClick={handleClick}
                      />
                      <Chip
                        label="Download PDF"
                        style={{"backgroundColor": "#015087", "color":"white","marginLeft":"2%", "marginTop":"3%", "width":"20%","fontSize":"18px" }}
                        onClick={handleClick}
                      />
                    </Box>
                </Grid>
            </Grid>
            </Container>
        </Paper>
    );
}

export default BenefitsSection;
import '../../App.css';
import { Container } from '@mui/material'
import Header from '../Header/Header';
import DemoFilter from './DemoFilter';
import DemoList from './DemoList';
import DemoListHeader from './DemoListHeader';

function DemoListPage() {
  return (
      <main>
          <Header />
          <Container sx={{ mt: 5, mb: 5 }}>
            <DemoListHeader/>
            <DemoFilter/>
            <br/>
            <DemoList/>
          </Container>
      </main>
  );
}

export default DemoListPage;
